var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center page px-2"},[_c('div',{staticClass:"flex flex-row flex-wrap w-full items-start content-start",style:({
      maxWidth: 700+'px',
    })},[_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-start py-4"},[_c('div',{staticClass:"w-full"},[_c('button',{staticClass:"back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Назад ")])]),(false)?_c('div',{staticClass:"sber-logo",on:{"click":function($event){return _vm.$router.go(-1)}}}):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full"},[_c('h2',{staticClass:"mr-4",staticStyle:{"color":"#40ff90"}},[_vm._v("SberCodject")]),(_vm.conf_id)?_c('img',{staticClass:"conf-logo",style:({
            width: '80px',
            height: '50px'
          }),attrs:{"src":"/images/confs/codefest.svg"}}):_vm._e()])]),(true || (_vm.task && _vm.gameInfo && _vm.gameInfo.current_level === 'final'))?_c('div',{staticClass:"flex flex-row flex-wrap w-full wrapper"},[_c('div',{staticClass:"flex flex-row w-full px-8 justify-between pt-2"},[_c('h2',{staticClass:"mb-4",class:{
            'text-yellow-400': _vm.conf_id === 'codefest_29',
            'text-gray-400': _vm.conf_id !== 'codefest_29'
          },style:({
            cursor: 'pointer'
          }),on:{"click":function($event){_vm.conf_id = 'codefest_29'}}},[_vm._v(" Domino ")]),_c('h2',{staticClass:"mb-4",class:{
            'text-yellow-400': _vm.conf_id === 'codefest_30',
            'text-gray-400': _vm.conf_id !== 'codefest_30'
          },style:({
            cursor: 'pointer'
          }),on:{"click":function($event){_vm.conf_id = 'codefest_30'}}},[_vm._v(" Syndicates ")])]),_c('codemirror',{ref:"cm",staticClass:"code-editor",style:({
        }),attrs:{"options":_vm.cmOptions},model:{value:(_vm.task.code),callback:function ($$v) {_vm.$set(_vm.task, "code", $$v)},expression:"task.code"}})],1):_c('div',{staticClass:"flex flex-row flex-wrap w-full wrapper items-start content-start p-8",style:({
        minHeight: '50px',
      })},[_c('p',{staticClass:"text-white"},[_vm._v(" SberCodject - это особая активность для программистов, в которой может принять участие любой специалист, проходивший SberQuiz 2021. Главным призом является СберПортал. Для того, чтобы его получить, придется предложить свои решения кодинговой задачи в рамках SberCodject. Это серия программистских заданий для поддержания в тонусе навыков кодописцев IT-индустрии. ")]),_c('div',{staticClass:"flex flex-row flex-wrap w-full text-white py-4"},[_vm._v(" Задание откроется после прохождения игры "),_c('div',{staticClass:"w-full pt-2"},[_c('a',{staticClass:"geecko-start",attrs:{"href":_vm.authUrl}},[_vm._v(_vm._s(_vm.startText))])])])]),_c('div',{staticClass:"flex flex-row flex-wrap w-full items-center content-center text-white p-8 wrapper mt-4",style:({
        marginBottom: '150px',
      })},[_c('p',{staticStyle:{"font-size":"20px","color":"white","margin-bottom":"20px"}},[_vm._v(" Решения ждем в срок до 17:00 для каждого дня конференции с темой \"SberCodject CodeFest\" на почту "),(true)?_c('a',{staticClass:"link text-white",attrs:{"href":"mailto:Kashirskaya.A.Al@sberbank.ru"}},[_vm._v("Kashirskaya.A.Al@sberbank.ru")]):_vm._e(),_vm._v(" Результаты и награждение в 18:00 каждый день конференции на оффлайн-стенде Сбера ")]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-wrap w-full"},[_c('div',{staticClass:"error mb-2"}),_c('h3',{staticStyle:{"color":"#6dedfc"}},[_vm._v(" Решение должно быть отправлено с почты, указанной при регистрации в игре ")])])}]

export { render, staticRenderFns }